import { useCallback, useEffect, useState } from 'react';

import * as ROUTE from '../constants/routes'
import useDidMount from './useDidMount';

const useSection = () => {
  const [sectionList, setSectionList] = useState([]);
  const [sectionIsLoading, setSectionLoading] = useState(false);
  const [error, setError] = useState('');
  const isDidMount = useDidMount();
  const fetchSectionList = useCallback(async () => {
    try {
      const authToken = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
      const requestOptions = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken.token}`
        }
      }
      if (sectionList.length === 0 && isDidMount) {
        setSectionLoading(true);
        setError('');
        const _sections =  await fetch(`${ROUTE.ADMIN_API}/courses/sections?paginate=0`, 
          requestOptions)
        .then(res => res.json())
        .then(data =>{
          return data
        })
        .catch(err => console.log(err))
        if (!_sections?.data) {
          if (isDidMount) {
            setError('No section found.');
            setSectionLoading(false);
          }
        } else {
          const data = _sections;
          if (isDidMount) {
            setSectionLoading(false);
            setSectionList(data);
          }
        }
      }
    } catch (e) {
      if (isDidMount) {
        console.log(e)
        setError('Failed to fetch sections');
        setSectionLoading(false);
      }
    }
  }, [isDidMount, sectionList]);

  useEffect(() => {
    fetchSectionList()
  }, [fetchSectionList]);

  return {
    fetchSectionList, sectionList, 
    sectionIsLoading, error
  };
};

export default useSection;