// @mui
import { Container } from '@mui/material';
// routes
import * as ROUTES from '../../../constants/routes';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// sections
import CreateLessonForm from '../../../sections/@dashboard/course/lessons/CreateLessonForm';

// ----------------------------------------------------------------------

export default function CreateLesson() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Create Lesson">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Create Lesson'
          links={[
            { name: 'Dashboard', href: ROUTES.PATH_ADMIN.root },
            { name: 'Lesson', href: ROUTES.PATH_ADMIN.courses.lessons },
            { name: 'Create' },
          ]}
        />

        <CreateLessonForm />
      </Container>
    </Page>
  );
}
