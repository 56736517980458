import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// form
import { useForm } from 'react-hook-form';
// @mui
import { styled } from '@mui/material/styles';
import { 
  Stack, Typography
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { updateTempQuiz } from '../../../../redux/actions/miscActions';
import { 
  RHFTextField, RHFSwitch, FormProvider
} from '../../../../components/hook-form';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

AddedQuiz.propTypes = {
  index: PropTypes.number,
  row: PropTypes.object
};

export default function AddedQuiz({ index, row }) {
  const dispatch = useDispatch();
  const { uuid, id, question, time, question_number, options } = row; //eslint-disable-line
  
  const defaultValues = useMemo(
    () => ({
      _questionNumber: question_number || '', //eslint-disable-line
      _question: question || '',
      _quizTime: time || '',
      _option1: options?.option_1?.option || '',
      _option2: options?.option_2?.option || '',
      _option3: options?.option_3?.option || '',
      _option4: options?.option_4?.option || '',
      _option5: options?.option_5?.option || '',
      _isAnswer1: options?.option_1?.is_answer || false,
      _isAnswer2: options?.option_2?.is_answer || false,
      _isAnswer3: options?.option_3?.is_answer || false,
      _isAnswer4: options?.option_4?.is_answer || false,
      _isAnswer5: options?.option_5?.is_answer || false
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const methods = useForm({
    defaultValues,
  });

  const {
    setValue,
    getValues,
  } = methods;

  const optionsMapping = [
    { optionValue: "_option1", optionLabel: "Option 1", answerValue: "_isAnswer1"},
    { optionValue: "_option2", optionLabel: "Option 2", answerValue: "_isAnswer2"},
    { optionValue: "_option3", optionLabel: "Option 3", answerValue: "_isAnswer3"},
    { optionValue: "_option4", optionLabel: "Option 4", answerValue: "_isAnswer4"},
    { optionValue: "_option5", optionLabel: "Option 5", answerValue: "_isAnswer5"}
    
  ];

  const handleQuiz = (field, value) => {
    if (field && value) {
      setValue(field, value);

      let quiz = null;
      if (options?.option_1?.id) {
        quiz = { 
          "id": id,
          "uuid": uuid,
          "question": getValues('_question'),
          "time":  getValues('_quizTime'),
          "question_number":  getValues('_questionNumber'),
          "options": {
            "option_1": {"id": options?.option_1?.id, "option": getValues('_option1'), "is_answer": getValues('_isAnswer1')},
            "option_2": {"id": options?.option_2?.id, "option": getValues('_option2'), "is_answer": getValues('_isAnswer2')},
            "option_3": {"id": options?.option_3?.id, "option": getValues('_option3'), "is_answer": getValues('_isAnswer3')},
            "option_4": {"id": options?.option_4?.id, "option": getValues('_option4'), "is_answer": getValues('_isAnswer4')},
            "option_5": {"id": options?.option_5?.id, "option": getValues('_option5'), "is_answer": getValues('_isAnswer5')}
          }
        };
      } else {
        quiz = { 
          "id": id,
          "uuid": uuid,
          "question": getValues('_question'),
          "time":  getValues('_quizTime'),
          "question_number":  getValues('_questionNumber'),
          "options": {
            "option_1": {"option": getValues('_option1'), "is_answer": getValues('_isAnswer1')},
            "option_2": {"option": getValues('_option2'), "is_answer": getValues('_isAnswer2')},
            "option_3": {"option": getValues('_option3'), "is_answer": getValues('_isAnswer3')},
            "option_4": {"option": getValues('_option4'), "is_answer": getValues('_isAnswer4')},
            "option_5": {"option": getValues('_option5'), "is_answer": getValues('_isAnswer5')}
          }
        };
      }
      dispatch(updateTempQuiz(quiz));
    }
  };

  return (
    <>
      <Stack>
        <FormProvider key={index} methods={methods}>
          <LabelStyle>Quiz</LabelStyle>
          <Stack spacing={3} mt={2}>
            <RHFTextField 
              name="_questionNumber"
              label="Question Number" 
              onChange={(e) => handleQuiz('_questionNumber', e.target.value)}
            />
            <ReactQuill 
              name={`_question_${uuid}`}
              theme="snow" 
              value={getValues('_question')} 
              placeholder="Write question..."
              onChange={(value) => handleQuiz('_question', value)}
            />
            <RHFTextField 
              name="_quizTime" 
              label="Estimated Time (mins)"  
              onChange={(e) => handleQuiz('_quizTime', e.target.value)}
            />
            {optionsMapping.map((option) => (
              <>
                <RHFTextField 
                  name={option.optionValue} 
                  label={option.optionLabel}
                  key={option.optionValue}
                  onChange={(e) => handleQuiz(option.optionValue, e.target.value)}
                />
                <RHFSwitch 
                  name={option.answerValue} 
                  label="Answer" 
                  key={option.answerValue}
                  onClick={(e) => handleQuiz(option.answerValue, e.target.checked)}
                  sx={{ m: 0 }}
                />
              </>
            ))}
          </Stack>
        </FormProvider>
      </Stack>
    </>
  );
}