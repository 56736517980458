import React, { Suspense } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import LoadingScreen from '../../../components/LoadingScreen';

const SearchParentsComponent = React.lazy(() => import('./components/SearchParents'));

const SearchParents = () => {
  const { searchKey } = useParams();
  const { pathname } = useLocation();

  return (
    <>
      <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
        <SearchParentsComponent 
          searchKey={searchKey} 
        />
      </Suspense>
    </>
  );
}

export default SearchParents;