import { useCallback, useEffect, useState } from 'react';

import * as ROUTE from '../constants/routes'
import useDidMount from './useDidMount';

const useCategory = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const isDidMount = useDidMount();

  const fetchCategoryList = useCallback(async () => {
    try {
      const authToken = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
      const requestOptions = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken.token}`
        }
      }
      if (categoryList.length === 0 && isDidMount) {
        setLoading(true);
        setError('');
        const _categories =  await fetch(`${ROUTE.ADMIN_API}/courses/categories`, 
          requestOptions)
        .then(res => res.json())
        .then(data =>{
          return data
        })
        .catch(err => console.log(err))
        if (!_categories?.data) {
          if (isDidMount) {
            setError('No category found.');
            setLoading(false);
          }
        } else {
          const data = _categories;
          if (isDidMount) {
            setLoading(false);
            setCategoryList(data);
          }
        }
      }
    } catch (e) {
      if (isDidMount) {
        console.log(e)
        setError('Failed to fetch categories');
        setLoading(false);
      }
    }
  }, [isDidMount, categoryList]);

  useEffect(() => {
    fetchCategoryList()
  }, [fetchCategoryList]);

  return {
    fetchCategoryList, categoryList, 
    isLoading, error
  };
};

export default useCategory;