// /admin/wallets/admin
import { useEffect, useState } from 'react';
import * as ROUTES from '../constants/routes';

export const useAdminWallet = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    const getAdminWalletData = () => {
        setLoading(true);
        const authToken = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${authToken.token}`,
            },
        };

        const url = `${ROUTES.ADMIN_API}/wallets/admin`;

        fetch(url, options)
            .then((res) => res.json())
            .then((data) => setData(data.data))
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getAdminWalletData();
    }, []);

    return { adminWallet: data, loading };
};
