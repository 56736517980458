import React, { Suspense } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import LoadingScreen from '../../../components/LoadingScreen';
import { decryptUrlId } from '../../../utils/helpers';

const UpdateLessonComponent = React.lazy(() => import('./components/UpdateLesson'));

const UpdateLesson = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const store = useSelector((state) => ({
    lessons: state.course.lessons
  }), shallowEqual);

  const decryptedUrlId = decryptUrlId(id);
  const lesson = store.lessons.find((lesson) => decryptedUrlId === lesson.id);
  return (
    <>
      {lesson && (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
          <UpdateLessonComponent 
            lesson={lesson}
          />
        </Suspense>
      )}
    </>
  );
}

export default UpdateLesson;