// @mui
import { Container } from '@mui/material';
// routes
import * as ROUTES from '../../../constants/routes';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// sections
import CreateSectionForm from '../../../sections/@dashboard/course/sections/CreateSectionForm';

// ----------------------------------------------------------------------

export default function CreateSection() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Create Section">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Create Section'
          links={[
            { name: 'Dashboard', href: ROUTES.PATH_ADMIN.root },
            { name: 'Section', href: ROUTES.PATH_ADMIN.courses.sections },
            { name: 'Create' },
          ]}
        />

        <CreateSectionForm />
      </Container>
    </Page>
  );
}
