import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { 
  Box,
  Card, Grid, Stack 
} from '@mui/material';
// routes
import * as ROUTES from '../../../../constants/routes';
import { AGE_BRACKET_TYPE } from '../../../../constants/constants';
import { FormProvider, RHFSelect, RHFTextField } from '../../../../components/hook-form';
import { createCategory } from '../../../../redux/actions/courseActions';
import { setRequestStatus } from '../../../../redux/actions/miscActions';

// ----------------------------------------------------------------------

export default function CreateCategoryForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const CreateCourseSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required'),
    description: Yup.string()
      .required('Description is required'),
    ageBracket: Yup.mixed()
      .required('Age bracket is required')
      .oneOf(AGE_BRACKET_TYPE),
  });

  const ageBracketType = [
    {
      id: AGE_BRACKET_TYPE[0],
      value: 'Age 6-12'
    },
    {
      id: AGE_BRACKET_TYPE[1],
      value: 'Age 13-18'
    },
    {
      id: AGE_BRACKET_TYPE[2],
      value: 'Age 19-25'
    }
  ];

  const defaultValues = useMemo(
    () => ({
      title: '',
      description: '',
      ageBracket: ''
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(CreateCourseSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const { profile, requestStatus, isLoading } = useSelector((state) => ({
    profile: state.profile,
    requestStatus: state.app.requestStatus,
    isLoading: state.app.loading
  }));

  useEffect(() => {
    dispatch(setRequestStatus(null));
    if (requestStatus?.message && !isLoading) {
      enqueueSnackbar(requestStatus.message, { variant: requestStatus.status })
      if (!requestStatus?.isError) {
        navigate(ROUTES.PATH_ADMIN.courses.category);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStatus, isLoading]);

  const onSubmit = (form) => {
    dispatch(createCategory({
      title: form.title.trim(),
      description: form.description.trim(),
      ageBracket: form.ageBracket,
      userId: profile.id
    }));
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
          <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
             <RHFTextField name="title" label="Post Title" />
              <RHFSelect name="ageBracket" label="Age Bracket" placeholder="Age Bracket">
                <option value="" />
                {ageBracketType.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </RHFSelect>
            </Box>
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <RHFTextField name="description" label="Description" multiline rows={3} />
            </Stack>
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isLoading}>
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
