import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';

import LoadingScreen from '../../../components/LoadingScreen';

const SectionComponent = React.lazy(() => import('./components/SectionList'));

const SectionList = () => {
  const { pathname } = useLocation();
  return (
    <div>
      <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
        <SectionComponent />
      </Suspense>
    </div>
  );
}

export default SectionList;