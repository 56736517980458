// @mui
import { Container } from '@mui/material';
// routes
import * as ROUTES from '../../constants/routes';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import CreateCourseForm from '../../sections/@dashboard/course/CreateCourseForm';

// ----------------------------------------------------------------------

export default function CreateCourse() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Create Course">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Create Course'
          links={[
            { name: 'Dashboard', href: ROUTES.PATH_ADMIN.root },
            { name: 'Course', href: ROUTES.PATH_ADMIN.courses.root },
            { name: 'Create' },
          ]}
        />

        <CreateCourseForm />
      </Container>
    </Page>
  );
}
