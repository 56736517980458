import React, { Suspense } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import LoadingScreen from '../../components/LoadingScreen';

const SearchWalletComponent = React.lazy(() => import('./components/SearchWallet'));

const SearchWallet = () => {
  const { searchKey } = useParams();
  const { pathname } = useLocation();

  return (
    <>
      <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
        <SearchWalletComponent 
          searchKey={searchKey} 
        />
      </Suspense>
    </>
  );
}

export default SearchWallet;