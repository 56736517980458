import {
  SET_WALLET, CLEAR_WALLET,
} from '../../constants/constants';

export const setWallet = (_payload = []) => ({
  type: SET_WALLET,
  payload: _payload
});

export const clearWallet = () => ({
  type: CLEAR_WALLET
});
