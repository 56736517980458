import {
  CREATE_CATEGORY, CREATE_COURSE, CREATE_LESSON,
  CREATE_SECTION, DELETE_CATEGORY, DELETE_COURSE,
  DELETE_LESSON, DELETE_SECTION, UPDATE_CATEGORY,
  UPDATE_COURSE, UPDATE_LESSON, UPDATE_SECTION,
  SET_LESSONS, SET_CATEGORIES, SET_COURSES,
  SET_SECTIONS, CLEAR_COURSES, CLEAR_CATEGORIES,
  CLEAR_LESSONS, CLEAR_SECTIONS
} from '../../constants/constants';

export const createCourse = (_payload) => ({
  type: CREATE_COURSE,
  payload: _payload
});

export const updateCourse = (updates) => ({
  type: UPDATE_COURSE,
  payload: updates
});

export const deleteCourse = (categoryId) => ({
  type: DELETE_COURSE,
  payload: categoryId
});

export const createCategory = (_payload) => ({
  type: CREATE_CATEGORY,
  payload: _payload
});

export const updateCategory = (updates) => ({
  type: UPDATE_CATEGORY,
  payload: updates
});

export const deleteCategory = (categoryId) => ({
  type: DELETE_CATEGORY,
  payload: categoryId
});

export const createSection = (_payload) => ({
  type: CREATE_SECTION,
  payload: _payload
});

export const updateSection = (updates) => ({
  type: UPDATE_SECTION,
  payload: updates
});

export const deleteSection = (sectionId) => ({
  type: DELETE_SECTION,
  payload: sectionId
});

export const setCourses = (payload = []) => ({
  type: SET_COURSES,
  payload: {
    courses: payload
  }
});

export const setCategories = (payload = []) => ({
  type: SET_CATEGORIES,
  payload: {
    categories: payload
  }
});

export const setLessons = (payload = []) => ({
  type: SET_LESSONS,
  payload: {
    lessons: payload
  }
});

export const setSections = (payload = []) => ({
  type: SET_SECTIONS,
  payload: {
    sections: payload
  }
});

export const createLesson = (payload) => ({
  type: CREATE_LESSON,
  payload: {
    lesson: payload.lesson,
    quizzes: payload?.quizzes,
  }
});

export const updateLesson = (updates) => ({
  type: UPDATE_LESSON,
  payload: {
    lesson: updates.lesson,
    quizzes: updates?.quizzes,
  }
});

export const deleteLesson = (lessonId) => ({
  type: DELETE_LESSON,
  payload: lessonId
});

export const clearCourses = () => ({
  type: CLEAR_COURSES
});

export const clearCategories = () => ({
  type: CLEAR_CATEGORIES
});

export const clearLessons = () => ({
  type: CLEAR_LESSONS
});

export const clearSections = () => ({
  type: CLEAR_SECTIONS
});