import { REQUEST_METHOD } from ".";
import * as ROUTES from '../constants/routes';

const ADMIN_AUTH_ROUTE = `${ROUTES.ADMIN_API}/auth`
const ADMIN_USERS_ROUTE = `${ROUTES.ADMIN_API}/users`
const ADMIN_COURSES_ROUTE = `${ROUTES.ADMIN_API}/courses`
const ADMIN_COURSES_CATEGORY = `${ADMIN_COURSES_ROUTE}/categories`
const ADMIN_COURSES_SECTION = `${ADMIN_COURSES_ROUTE}/sections`
const ADMIN_COURSES_LESSON = `${ADMIN_COURSES_ROUTE}/lessons`
const ADMIN_WALLET_ROUTE = `${ROUTES.ADMIN_API}/wallets`

/** Auth endpoints */
export function signUp(data) {
	return REQUEST_METHOD.post(`${ADMIN_AUTH_ROUTE}/register`, data)
}
export function signIn(data) {
	return REQUEST_METHOD.authHeader(`${ADMIN_AUTH_ROUTE}/login`, data)
}
export function verifyEmail(data) {
	return REQUEST_METHOD.post(`${ADMIN_AUTH_ROUTE}/verify-email`, data)
}
export function resendVerifyEmailCode(data) {
	return REQUEST_METHOD.post(`${ADMIN_AUTH_ROUTE}/verify-email/resend`, data)
}
export function forgotPassword(data) {
	return REQUEST_METHOD.post(`${ADMIN_AUTH_ROUTE}/forgot-password`, data)
}
export function resendEmailOtpCode(kwargs) {
	return REQUEST_METHOD.post(`${ADMIN_AUTH_ROUTE}/verify-email/resend`, kwargs)
}
export function verifyForgotPassword(kwargs) {
	return REQUEST_METHOD.post(`${ADMIN_AUTH_ROUTE}/forget-password/verification`, kwargs)
}
export function resetPassword(kwargs) {
	return REQUEST_METHOD.post(`${ADMIN_AUTH_ROUTE}/reset-password`, kwargs)
}
export function signOut(kwargs) {
	const data = {}
	return REQUEST_METHOD.post(`${ADMIN_AUTH_ROUTE}/logout`, data, kwargs)
}


/** User endpoints */
export function getAuthenticatedUser(kwargs) {
	return REQUEST_METHOD.get(`${ADMIN_USERS_ROUTE}/my-profile`, kwargs)
}
export function upgradeKycTier1(data, kwargs) {
	return REQUEST_METHOD.put(`${ADMIN_USERS_ROUTE}/upgrade/t1`, data, kwargs)
}
export function upgradeKycTier2(data, kwargs) {
	return REQUEST_METHOD.put(`${ADMIN_USERS_ROUTE}/upgrade/t2`, data, kwargs)
}
export function blockUser(data, kwargs){
	return REQUEST_METHOD.post(`${ADMIN_USERS_ROUTE}/block`, data, kwargs)
}

/** Course endpoints */
export function createCourse(data, kwargs) {
	return REQUEST_METHOD.post(`${ADMIN_COURSES_ROUTE}`, data, kwargs)
}
export function getCourseById(courseId) {
	return REQUEST_METHOD.get(`${ADMIN_COURSES_ROUTE}?id=${courseId}`)
}
export function updateCourseById(courseId, data, kwargs) {
	return REQUEST_METHOD.put(`${ADMIN_COURSES_ROUTE}/${courseId}`, data, kwargs)
}
export function deleteCourseById(courseId, kwargs) {
	return REQUEST_METHOD.delete_(`${ADMIN_COURSES_ROUTE}/${courseId}`, kwargs)
}
export function createCategory(data, kwargs) {
	return REQUEST_METHOD.post(`${ADMIN_COURSES_CATEGORY}`, data, kwargs)
}
export function getCategories(kwargs) {
	return REQUEST_METHOD.get(`${ADMIN_COURSES_CATEGORY}`, kwargs)
}
export function getCategoryById(categoryId) {
	return REQUEST_METHOD.get(`${ADMIN_COURSES_CATEGORY}?id=${categoryId}`)
}
export function updateCategoryById(categoryId, data, kwargs) {
	return REQUEST_METHOD.put(`${ADMIN_COURSES_CATEGORY}/${categoryId}`, data, kwargs)
}
export function deleteCategoryById(categoryId, kwargs) {
	return REQUEST_METHOD.delete_(`${ADMIN_COURSES_CATEGORY}/${categoryId}`, kwargs)
}
export function createSection(data, kwargs) {
	return REQUEST_METHOD.post(`${ADMIN_COURSES_SECTION}`, data, kwargs)
}
export function getSections(kwargs) {
	return REQUEST_METHOD.get(`${ADMIN_COURSES_SECTION}`, kwargs)
}
export function getSectionById(sectionId) {
	return REQUEST_METHOD.get(`${ADMIN_COURSES_SECTION}?id=${sectionId}`)
}
export function updateSectionById(sectionId, data, kwargs) {
	return REQUEST_METHOD.put(`${ADMIN_COURSES_SECTION}/${sectionId}`, data, kwargs)
}
export function deleteSectionById(sectionId, kwargs) {
	return REQUEST_METHOD.delete_(`${ADMIN_COURSES_SECTION}/${sectionId}`, kwargs)
}
export function createLesson(data, kwargs) {
	return REQUEST_METHOD.post(`${ADMIN_COURSES_LESSON}`, data, kwargs)
}
export function getLessons(kwargs) {
	return REQUEST_METHOD.get(`${ADMIN_COURSES_LESSON}`, kwargs)
}
export function getLessonById(lessonId) {
	return REQUEST_METHOD.get(`${ADMIN_COURSES_LESSON}?id=${lessonId}`)
}
export function updateLessonById(lessonId, data, kwargs) {
	return REQUEST_METHOD.put(`${ADMIN_COURSES_LESSON}/${lessonId}`, data, kwargs)
}
export function deleteLessonById(lessonId, kwargs) {
	return REQUEST_METHOD.delete_(`${ADMIN_COURSES_LESSON}/${lessonId}`, kwargs)
}
export function createQuiz(data, kwargs) {
	return REQUEST_METHOD.post(`${ADMIN_COURSES_LESSON}/quiz`, data, kwargs)
}
export function getQuizzes(kwargs) {
	return REQUEST_METHOD.get(`${ADMIN_COURSES_LESSON}/quiz`, kwargs)
}
export function getQuizById(quizId) {
	return REQUEST_METHOD.get(`${ADMIN_COURSES_LESSON}/quiz?id=${quizId}`)
}
export function getQuizByLessonId(lessonId) {
	return REQUEST_METHOD.get(`${ADMIN_COURSES_LESSON}/quiz?lesson_id=${lessonId}`)
}
export function updateQuizById(data, kwargs) {
	return REQUEST_METHOD.put(`${ADMIN_COURSES_LESSON}/quiz`, data, kwargs)
}
export function deleteQuizById(quizId, kwargs) {
	return REQUEST_METHOD.delete_(`${ADMIN_COURSES_LESSON}/quiz/${quizId}`, kwargs)
}

/** Wallet endpoints */
export function creditWallet(data, kwargs) {
	return REQUEST_METHOD.post(`${ADMIN_WALLET_ROUTE}/credit`, data, kwargs)
}
export function createChildWallet(data, kwargs) {
	return REQUEST_METHOD.post(`${ADMIN_WALLET_ROUTE}/children`, data, kwargs)
}