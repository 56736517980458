import {
  CLEAR_CHILD_LIST,
  CLEAR_PARENT_LIST,
  IS_AUTHENTICATING, LOADING,
  SET_AUTH_STATUS, SET_CHILD_LIST, 
  SET_PARENT_LIST, SET_REQUEST_STATUS,
  SET_TEMP_QUIZ, UPDATE_TEMP_QUIZ, CLEAR_TEMP_QUIZ
} from '../../constants/constants';

export const setLoading = (bool = true) => ({
  type: LOADING,
  payload: bool
});

export const setAuthenticating = (bool = true) => ({
  type: IS_AUTHENTICATING,
  payload: bool
});

export const setRequestStatus = (status) => ({
  type: SET_REQUEST_STATUS,
  payload: status
});

export const setAuthStatus = (status = null) => ({
  type: SET_AUTH_STATUS,
  payload: status
});

export const setParentsList = (_payload = []) => ({
  type: SET_PARENT_LIST,
  payload: _payload
});

export const clearParentList = () => ({
  type: CLEAR_PARENT_LIST
});

export const setChildList = (_payload = []) => ({
  type: SET_CHILD_LIST,
  payload: _payload
});

export const setTempQuiz = (_quiz, _rowOrder) => ({
  type: SET_TEMP_QUIZ,
  quiz: _quiz,
  rowOrder: _rowOrder
});

export const updateTempQuiz = (_quiz) => ({
  type: UPDATE_TEMP_QUIZ,
  quiz: _quiz
});

export const clearTempQuiz = () => ({
  type: CLEAR_TEMP_QUIZ
});

export const clearChildList = () => ({
  type: CLEAR_CHILD_LIST
});