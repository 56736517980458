export const SIGNIN = 'SIGNIN';
export const SIGNUP = 'SIGNUP';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE';
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK';
export const ON_AUTHSTATE_CHANGED = 'ON_AUTHSTATE_CHANGED';
export const SET_AUTH_PERSISTENCE = 'SET_AUTH_PERSISTENCE';
export const ON_AUTHSTATE_SUCCESS = 'ON_AUTHSTATE_SUCCESS';
export const ON_AUTHSTATE_FAIL = 'ON_AUTHSTATE_FAIL';
export const RESEND_VERIFY_EMAIL_CODE = 'RESEND_VERIFY_EMAIL_CODE';
export const VERIFY_EMAIL_CODE = 'VERIFY_EMAIL_CODE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';

export const LOADING = 'LOADING';
export const IS_AUTHENTICATING = 'IS_AUTHENTICATING';
export const SET_REQUEST_STATUS = 'SET_REQUEST_STATUS';

export const SET_PROFILE = 'SET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';

export const GET_USER = 'GET_USER';
export const SET_PARENT_LIST = 'GET_USET_PARENT_LISTSER';
export const CLEAR_PARENT_LIST = 'CLEAR_PARENT_LIST';
export const SET_CHILD_LIST = 'SET_CHILD_LIST';
export const CLEAR_CHILD_LIST = 'CLEAR_CHILD_LIST';
export const UPGRADE_KYC_TIER_1 = 'UPGRADE_KYC_TIER_1';
export const UPGRADE_KYC_TIER_2 = 'UPGRADE_KYC_TIER_2';
export const CREDIT_WALLET = 'CREDIT_WALLET';
export const CREATE_CHILD_WALLET = 'CREATE_CHILD_WALLET';

export const SET_WALLET = 'SET_WALLET';
export const CLEAR_WALLET = 'CLEAR_WALLET';

export const SET_COURSES = 'SET_COURSES';
export const CREATE_COURSE = 'CREATE_COURSE';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const DELETE_COURSE = 'DELETE_COURSE';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const SET_SECTIONS = 'SET_SECTIONS';
export const CREATE_SECTION = 'CREATE_SECTION';
export const DELETE_SECTION = 'DELETE_SECTION';
export const UPDATE_SECTION = 'UPDATE_SECTION';
export const SET_LESSONS = 'SET_LESSONS';
export const CREATE_LESSON = 'CREATE_LESSON';
export const DELETE_LESSON = 'DELETE_LESSON';
export const UPDATE_LESSON = 'UPDATE_LESSON';
export const UPDATE_TEMP_QUIZ = 'UPDATE_TEMP_QUIZ';
export const SET_TEMP_QUIZ = 'SET_TEMP_QUIZ';
export const CLEAR_COURSES = 'CLEAR_COURSES';
export const CLEAR_CATEGORIES = 'CLEAR_CATEGORIES';
export const CLEAR_SECTIONS = 'CLEAR_SECTIONS';
export const CLEAR_LESSONS = 'CLEAR_LESSONS';
export const CLEAR_TEMP_QUIZ = 'CLEAR_TEMP_QUIZ';

export const EMAIL_VERIFICATION_SUCCESS_MESSAGE = 'Email verification successful. Redirecting...';

// other constants
export const GENDER_OPTION = ['male', 'female'];
export const WALLET_TYPE = ['main', 'custodial'];
export const AGE_BRACKET_TYPE = ['AGE_6_12', 'AGE_13_18', 'AGE_19_25'];
export const LESSON_TYPE_TYPE = ['VIDEO', 'ARTICLE'];
export const IS_COURSE_LOCKED = [ {id: 0, value: 'unlock'}, { id: 1, value: 'lock' }];
