function path(root, sublink) {
  return `${root}${sublink}`;
}

/* API routes */
export const ADMIN_API = process.env.REACT_APP_ADMIN_API;
export const APP_API = process.env.REACT_APP_API;

/* Auth routes */
const ROOTS_AUTH = '/auth';
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  signIn: path(ROOTS_AUTH, '/signin'),
  signUp: path(ROOTS_AUTH, '/signup'),
  logout: path(ROOTS_AUTH, '/logout'),
  verifyCode: path(ROOTS_AUTH, '/verify'),
};

export const SIGNIN = '/auth/signin';
export const SIGNOUT = '/auth/logout';
export const SIGNUP = '/auth/signup';
export const VERIFY_CODE = '/auth/verify';

/* Admin routes */
export const ADMIN_DASHBOARD = '/';

export const PATH_ADMIN = {
  root: path(ADMIN_DASHBOARD, 'dashboard'),
  users: {
    root: path(ADMIN_DASHBOARD, 'users'),
    profile: path(ADMIN_DASHBOARD, 'users/profile'),
    account: path(ADMIN_DASHBOARD, 'users/account'),
    parent: path(ADMIN_DASHBOARD, 'users/parent'),
    searchParent: path(ADMIN_DASHBOARD, 'users/parent/search/:searchKey'),
    viewParent: path(ADMIN_DASHBOARD, 'users/parent/:id'),
    kycUpgrade1: path(ADMIN_DASHBOARD, 'users/:id/kyc-upgrade-1'),
    kycUpgrade2: path(ADMIN_DASHBOARD, 'users/:id/kyc-upgrade-2'),
    children: path(ADMIN_DASHBOARD, 'users/children'),
    searchChildren: path(ADMIN_DASHBOARD, 'users/children/search/:searchKey'),
    viewChild: path(ADMIN_DASHBOARD, 'users/child/:id'),
    phoneCode: path(ADMIN_DASHBOARD, 'users/phone/:id/:name'),
    emailCode: path(ADMIN_DASHBOARD, 'users/email/:id/:name'),
  },
  transactions: {
    root: path(ADMIN_DASHBOARD, 'transactions'),
  },
  wallet: {
    root: path(ADMIN_DASHBOARD, 'wallet'),
    parent: path(ADMIN_DASHBOARD, 'wallet/parent'),
    children: path(ADMIN_DASHBOARD, 'wallet/children'),
    createChildWallet: path(ADMIN_DASHBOARD, 'wallet/child/:parentProfileId/create'),
    credit: path(ADMIN_DASHBOARD, 'wallet/:id/credit'),
    view: path(ADMIN_DASHBOARD, 'wallet/:id'),
    searchWallet: path(ADMIN_DASHBOARD, 'wallet/search/:searchKey'),
  },
  courses: {
    root: path(ADMIN_DASHBOARD, 'courses'),
    category: path(ADMIN_DASHBOARD, 'courses/category'),
    sections: path(ADMIN_DASHBOARD, 'courses/sections'),
    lessons: path(ADMIN_DASHBOARD, 'courses/lessons'),
    lessonsCourses: path(ADMIN_DASHBOARD, 'courses/lessons/courses-frag'),
    lessonsCourseSections: path(ADMIN_DASHBOARD, 'courses/lessons/sections/:courseId'),
    lessonsSections: path(ADMIN_DASHBOARD, 'courses/lessons/:sectionId'),
    create: path(ADMIN_DASHBOARD, 'courses/create'),
    createCategory: path(ADMIN_DASHBOARD, 'courses/category/create'),
    createSection: path(ADMIN_DASHBOARD, 'courses/section/create'),
    createLessons: path(ADMIN_DASHBOARD, 'courses/lesson/create'),
    update: path(ADMIN_DASHBOARD, 'courses/:id/update'),
    updateCategory: path(ADMIN_DASHBOARD, 'courses/category/:id/update'),
    updateLesson: path(ADMIN_DASHBOARD, 'courses/lessons/:id/update'),
    updateSection: path(ADMIN_DASHBOARD, 'courses/sections/:id/update'),
  },
};

/* Static routes */
export const PAGE404 = '/*';
