import React, { Suspense } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import LoadingScreen from '../../../components/LoadingScreen';

const CreateChildWalletComponent = React.lazy(() => import('./components/CreateChildWallet'));

const CreateChildWallet = () => {
  const { parentProfileId } = useParams();
  const { pathname } = useLocation();

  const store = useSelector((state) => ({
	  parentsList: state.app.parentsList
  }), shallowEqual);

  const parent = store.parentsList.find((parent) => parentProfileId === parent.id);
  console.log(store)
  return (
    <>
      {parent && (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
          <CreateChildWalletComponent
            parent={parent}
          />
        </Suspense>
      )}
    </>
  );
}

export default CreateChildWallet;