import {
  CLEAR_CHILD_LIST,
  CLEAR_PARENT_LIST,
  IS_AUTHENTICATING, LOADING,
  SET_AUTH_STATUS,
  SET_CHILD_LIST,
  SET_PARENT_LIST,
  SET_REQUEST_STATUS, SET_TEMP_QUIZ,
  UPDATE_TEMP_QUIZ, CLEAR_TEMP_QUIZ
} from '../../constants/constants';

const initTempQuiz = {
  quiz: {},
  rowOrder: []
};

const initState = {
  loading: false,
  isAuthenticating: false,
  authStatus: null,
  requestStatus: null,
  parentsList: [],
  kidsList: [],
  tempQuiz: initTempQuiz
};

export default (state = initState, action) => { // eslint-disable-line
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case IS_AUTHENTICATING:
      return {
        ...state,
        isAuthenticating: action.payload
      };
    case SET_REQUEST_STATUS:
      return {
        ...state,
        requestStatus: action.payload
      };
    case SET_AUTH_STATUS:
      return {
        ...state,
        authStatus: action.payload
      };
    case SET_PARENT_LIST:
      return  {
          ...state,
          parentsList: action.payload
        }
    case CLEAR_PARENT_LIST:
      return  {
        ...state,
        parentsList: []
      }
    case SET_CHILD_LIST:
      return  {
        ...state,
        kidsList: action.payload
      }
    case CLEAR_CHILD_LIST:
      return  {
        ...state,
        kidsList: []
      }
    case SET_TEMP_QUIZ:
        state.tempQuiz.quiz[action.quiz.uuid] = action.quiz;
        state.tempQuiz.rowOrder.push(action.rowOrder.id);
  
        return {
          ...state
        };
    case UPDATE_TEMP_QUIZ:
      state.tempQuiz.quiz[action.quiz.uuid] = action.quiz;
      
      return {
        ...state
      };
    case CLEAR_TEMP_QUIZ:
        return  {
          ...state,
          tempQuiz: {
            quiz: {},
            rowOrder: []
          }
        }
    default:
      return state;
  }
};
