import React, { Suspense } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import LoadingScreen from '../../../components/LoadingScreen';

const ViewChildComponent = React.lazy(() => import('./components/ViewChild'));

const ViewChild = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const store = useSelector((state) => ({
	  kidsList: state.app.kidsList
  }), shallowEqual);

  const child = store.kidsList.find((child) => id === child.id);

  return (
    <>
      {child && (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
          <ViewChildComponent 
            child={child}
          />
        </Suspense>
      )}
    </>
  );
}

export default ViewChild;