import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { setAuthenticating, setAuthStatus } from '../../../redux/actions/miscActions';
import { signIn } from '../../../redux/actions/authActions';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { isAuthenticating, authStatus } = useSelector((state) => ({
      isAuthenticating: state.app.isAuthenticating,
      authStatus: state.app.authStatus
  }));

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is not valid.')
      .required('Email is required.'),
    password: Yup.string()
      .required('Password is required.')
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });
  const { handleSubmit } = methods;

  useEffect(() => {
      dispatch(setAuthStatus(null));
      dispatch(setAuthenticating(false));
  }, [dispatch]);

  useEffect(() => {
    if (authStatus?.message && !isAuthenticating) {
      enqueueSnackbar(authStatus.message, { variant: authStatus.status })
    }
}, [enqueueSnackbar, authStatus, isAuthenticating]);

  const onSubmit = (form) => {
      dispatch(signIn(form.email, form.password));
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isAuthenticating}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
