import authReducer from './authReducer';
import miscReducer from './miscReducer';
import profileReducer from './profileReducer';
import userReducer from './userReducer';
import courseReducer from './courseReducer';
import walletReducer from './walletReducer';

const rootReducer = {
  auth: authReducer,
  profile: profileReducer,
  users: userReducer,
  course: courseReducer,
  wallet: walletReducer,
  app: miscReducer
};

export default rootReducer;
