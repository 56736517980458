import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { 
  Box, Card, Grid, Stack,
  Typography 
} from '@mui/material';
// utils
import { fData } from '../../../utils/formatNumber';
// routes
import * as ROUTES from '../../../constants/routes';
import { AGE_BRACKET_TYPE } from '../../../constants/constants';

// components
import { 
  FormProvider, RHFSelect, RHFTextField, RHFUploadAvatar 
} from '../../../components/hook-form';

import { createCourse } from '../../../redux/actions/courseActions';
import { setRequestStatus } from '../../../redux/actions/miscActions';
import { useCategory } from '../../../hooks';

// ----------------------------------------------------------------------

export default function CreateCourseForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { categoryList } = useCategory();

  const CreateCourseSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required'),
    description: Yup.string()
      .required('Description is required'),
    category: Yup.string()
      .required('Category is required'),
    ageBracket: Yup.mixed()
      .required('Age bracket is required')
      .oneOf(AGE_BRACKET_TYPE),
    coverImage: Yup.mixed()
      .test('required', 'Image is required', (value) => value !== ''),
  });

  const defaultValues = useMemo(
    () => ({
      title: '',
      description: '',
      category: '',
      ageBracket: '',
      coverImage: ''
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(CreateCourseSchema),
    defaultValues,
  });

  const {
    getValues,
    setValue,
    handleSubmit,
  } = methods;

  const { profile, requestStatus, isLoading } = useSelector((state) => ({
    profile: state.profile,
    requestStatus: state.app.requestStatus,
    isLoading: state.app.loading
  }));

  const categories = categoryList ? categoryList?.data?.data.map((category) => ({
    id: category?.id,
    title: category?.title
  })) : [];

  const ageBracketType = [
    {
      id: AGE_BRACKET_TYPE[0],
      value: 'Age 6-12'
    },
    {
      id: AGE_BRACKET_TYPE[1],
      value: 'Age 13-18'
    },
    {
      id: AGE_BRACKET_TYPE[2],
      value: 'Age 19-25'
    }
  ];

  useEffect(() => {
    dispatch(setRequestStatus(null));
    if (requestStatus?.message && !isLoading) {
      enqueueSnackbar(requestStatus.message, { variant: requestStatus.status })
      if (!requestStatus?.isError) {
        navigate(ROUTES.PATH_ADMIN.courses.createSection);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStatus, isLoading]);

  const onSubmit = (form) => {
    dispatch(createCourse({
      title: form.title.trim(),
      description: form.description.trim(),
      category_id: form.category,
      cover_image: getValues('coverImage'),
      age_bracket: form.ageBracket,
      user_id: profile.id
    }));
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const img = acceptedFiles[0];
      const reader = new FileReader();

      if (img) {
        reader.addEventListener('load', (e) => {
          setValue('coverImage', e.target.result);
        });
        reader.readAsDataURL(img);
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3 }}>
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="coverImage"
                accept="image/x-png,image/jpeg"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="title" label="Title" />
              <RHFSelect name="category" label="Category" placeholder="Category">
                <option value="" />
                {categories && categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.title}
                  </option>
                ))}
              </RHFSelect>

              <RHFSelect name="ageBracket" label="Age Bracket" placeholder="Age Bracket">
                <option value="" />
                {ageBracketType.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </RHFSelect>
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <RHFTextField name="description" multiline rows={4} label="Description" />
            </Stack>
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isLoading}>
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
