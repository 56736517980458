import React, { Suspense } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import LoadingScreen from '../../components/LoadingScreen';

const EmailCodeListComponent = React.lazy(() => import('./components/EmailCodeList'));

const EmailCodeList = () => {
  const { id } = useParams();
  const { name } = useParams();
  const { pathname } = useLocation();

  return (
    <>
      <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
        <EmailCodeListComponent 
          userId={id}
          name={name}
        />
      </Suspense>
    </>
  );
}

export default EmailCodeList;