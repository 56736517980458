import { call, put } from 'redux-saga/effects';

import { 
	CREATE_CATEGORY, CREATE_COURSE, CREATE_LESSON, 
	CREATE_SECTION, DELETE_CATEGORY, DELETE_COURSE, DELETE_LESSON, 
	DELETE_SECTION, UPDATE_CATEGORY, UPDATE_COURSE, UPDATE_LESSON, 
	UPDATE_SECTION 
} from '../../constants/constants';
import { clearTempQuiz, setLoading, setRequestStatus } from '../actions/miscActions';
import { ADMIN_API } from '../../services';

function* initRequest() {
	yield put(setLoading(true));
	yield put(setRequestStatus(null));
}

function* courseSaga({type, payload}) {
	switch (type) {
		case CREATE_COURSE: {
			try {
				yield initRequest();
		
				const authTokens = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
				if (authTokens) {
					const res = yield call(ADMIN_API.createCourse, payload, {token: authTokens.token});
					yield put(setRequestStatus({
						success: true,
						status: 'success',
						type: 'courses',
						isError: false,
						message: res.message
					}));
				}
				yield put(setLoading(false));

			} catch (e) {
				console.log(e);
				yield put(setLoading(false));
				yield put(setRequestStatus({
					success: false,
					status: 'error',
					type: 'courses',
					isError: true,
					message: e.message
				}));
			}
			break;
		}
		case UPDATE_COURSE: {
			try {
				yield initRequest();
		
				const authTokens = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
				if (authTokens) {
					const courseId = payload.id
					const image = payload.coverImage;
					const isImageFile = !!image
					let update = {};
					if (isImageFile) {
						update = {
							title: payload.title,
							description: payload.description,
							category_id: payload.categoryId,
							cover_image: image,
							user_id: payload.userId
						};
					} else {
						update = {
							title: payload.title,
							description: payload.description,
							category_id: payload.categoryId,
							user_id: payload.userId
						};
					}
					const res = yield call(ADMIN_API.updateCourseById, courseId, update, {token: authTokens.token});
					yield put(setRequestStatus({
						success: true,
						status: 'success',
						type: 'course',
						isError: false,
						message: res.message
					}));
				}
				yield put(setLoading(false));

			} catch (e) {
				console.log(e);
				yield put(setLoading(false));
				yield put(setRequestStatus({
					success: false,
					type: 'course',
					status: 'error',
					isError: true,
					message: 'Error updating. Wrong inputs validation.'
				}));
			}
			break;
		}
		case DELETE_COURSE: {
			try {
				yield initRequest();
		
				const authTokens = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
				if (authTokens) {
					const courseId = payload;
					yield call(ADMIN_API.deleteCourseById, courseId, {token: authTokens.token});
				}
				yield put(setLoading(false));

			} catch (e) {
				console.log(e);
				yield put(setLoading(false));
				yield put(setRequestStatus({
					success: false,
					type: 'course',
					status: 'error',
					isError: true,
					message: e.message
				}));
			}
			break;
		}
		case CREATE_CATEGORY: {
			try {
				yield initRequest();
		
				const authTokens = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
				if (authTokens) {
					const data = {
						title: payload.title,
						description: payload.description,
						age_bracket: payload.ageBracket,
						user_id: payload.userId
					};
					const res = yield call(ADMIN_API.createCategory, data, {token: authTokens.token});
					yield put(setRequestStatus({
						success: true,
						status: 'success',
						type: 'category',
						isError: false,
						message: res.message
					}));
				}
				yield put(setLoading(false));

			} catch (e) {
				console.log(e);
				yield put(setLoading(false));
				yield put(setRequestStatus({
					success: false,
					type: 'category',
					status: 'error',
					isError: true,
					message: e.message
				}));
			}
			break;
		}
		case UPDATE_CATEGORY: {
			try {
				yield initRequest();
		
				const authTokens = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
				if (authTokens) {
					const categoryId = payload.id
					const update = {
						title: payload.title,
						description: payload.description,
						age_bracket: payload.ageBracket,
						user_id: payload.userId
					};
					const res = yield call(ADMIN_API.updateCategoryById, categoryId, update, {token: authTokens.token});
					yield put(setRequestStatus({
						success: true,
						status: 'success',
						type: 'category',
						isError: false,
						message: res.message
					}));
				}
				yield put(setLoading(false));

			} catch (e) {
				console.log(e);
				yield put(setLoading(false));
				yield put(setRequestStatus({
					success: false,
					type: 'category',
					status: 'error',
					isError: true,
					message: e.message
				}));
			}
			break;
		}
		case DELETE_CATEGORY: {
			try {
				yield initRequest();
		
				const authTokens = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
				if (authTokens) {
					const categoryId = payload;
					yield call(ADMIN_API.deleteCategoryById, categoryId, {token: authTokens.token});
				}
				yield put(setLoading(false));

			} catch (e) {
				console.log(e);
				yield put(setLoading(false));
				yield put(setRequestStatus({
					success: false,
					type: 'category',
					status: 'error',
					isError: true,
					message: e.message
				}));
			}
			break;
		}
		case CREATE_SECTION: {
			try {
				yield initRequest();
		
				const authTokens = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
				if (authTokens) {
					const data = {
						title: payload.title,
						course_id: payload.courseId,
						section_number: payload.sectionNumber
					};
					const res = yield call(ADMIN_API.createSection, data, {token: authTokens.token});
					yield put(setRequestStatus({
						success: true,
						status: 'success',
						type: 'section',
						isError: false,
						message: res.message
					}));
				}
				yield put(setLoading(false));

			} catch (e) {
				console.log(e);
				yield put(setLoading(false));
				yield put(setRequestStatus({
					success: false,
					type: 'section',
					status: 'error',
					isError: true,
					message: e.message
				}));
			}
			break;
		}
		case UPDATE_SECTION: {
			try {
				yield initRequest();
		
				const authTokens = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
				if (authTokens) {
					const sectionId = payload.id
					const update = {
						title: payload.title,
						course_id: payload.courseId,
						section_number: payload.sectionNumber
					};
					const res = yield call(ADMIN_API.updateSectionById, sectionId, update, {token: authTokens.token});
					yield put(setRequestStatus({
						success: true,
						status: 'success',
						type: 'section',
						isError: false,
						message: res.message
					}));
				}
				yield put(setLoading(false));

			} catch (e) {
				console.log(e);
				yield put(setLoading(false));
				yield put(setRequestStatus({
					success: false,
					type: 'section',
					status: 'error',
					isError: true,
					message: e.message
				}));
			}
			break;
		}
		case DELETE_SECTION: {
			try {
				yield initRequest();
		
				const authTokens = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
				if (authTokens) {
					const sectionId = payload;
					yield call(ADMIN_API.deleteSectionById, sectionId, {token: authTokens.token});
				}
				yield put(setLoading(false));

			} catch (e) {
				console.log(e);
				yield put(setLoading(false));
				yield put(setRequestStatus({
					success: false,
					type: 'section',
					status: 'error',
					isError: true,
					message: e.message
				}));
			}
			break;
		}
		case CREATE_LESSON: {
			try {
				yield initRequest();
		
				const authTokens = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
				if (authTokens) {
					let data = null
					let quizData = null
					if (payload.lesson && payload.lesson.contentUrl){
						data = {
							title: payload.lesson.title,
							content_url: payload.lesson.contentUrl,
							section_id: payload.lesson.sectionId,
							is_locked: payload.lesson.isLocked,
							lesson_number: payload.lesson.lessonNumber,
							lesson_type: payload.lesson.lessonType,
							estimated_time: payload.lesson.estimatedTime
						};
					}
					if (payload.lesson && payload.lesson.contentFile){
						data = {
							title: payload.lesson.title,
							content_file: payload.lesson.contentFile,
							section_id: payload.lesson.sectionId,
							is_locked: payload.lesson.isLocked,
							lesson_number: payload.lesson.lessonNumber,
							lesson_type: payload.lesson.lessonType,
							estimated_time: payload.lesson.estimatedTime
						};
					} 
					if (payload.lesson && payload.lesson.content){
						data = {
							title: payload.lesson.title,
							content: payload.lesson.content,
							section_id: payload.lesson.sectionId,
							is_locked: payload.lesson.isLocked,
							lesson_number: payload.lesson.lessonNumber,
							lesson_type: payload.lesson.lessonType,
							estimated_time: payload.lesson.estimatedTime
						};
					}

					const res = yield call(ADMIN_API.createLesson, data, {token: authTokens.token});
					if (res && payload.lesson.lessonHasQuiz) {
						const lessonId = res.data.id;
						if (payload?.quizzes) {
							quizData = {
								lesson_id: lessonId,
								quizzes: payload?.quizzes
							}
						}
						yield call(ADMIN_API.createQuiz, quizData, {token: authTokens.token});
						yield put(clearTempQuiz());
					}
					yield put(setRequestStatus({
						success: true,
						status: 'success',
						type: 'lesson',
						isError: false,
						message: res.message
					}));
				}
				yield put(setLoading(false));

			} catch (e) {
				console.log(e);
				yield put(setLoading(false));
				yield put(setRequestStatus({
					success: false,
					type: 'lesson',
					status: 'error',
					isError: true,
					message: e.message
				}));
			}
			break;
		}
		case UPDATE_LESSON: {
			try {
				yield initRequest();
		
				const authTokens = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
				if (authTokens) {
					const lessonId = payload.lesson.id
					const isContent = !!payload.lesson.content
					const isContentUrl = !!payload.lesson.contentUrl
					const isContentFile = !!payload.lesson.contentFile
					
					let quizData = null
					let update = null;
					if (lessonId && isContentUrl) {
						update = {
							title: payload.lesson.title,
							content_url: payload.lesson.contentUrl,
							section_id: payload.lesson.sectionId,
							is_locked: payload.lesson.isLocked,
							lesson_number: payload.lesson.lessonNumber,
							lesson_type: payload.lesson.lessonType,
							estimated_time: payload.lesson.estimatedTime
						};
					} 
					if (lessonId && isContentFile) {
						update = {
							title: payload.lesson.title,
							content_file: payload.lesson.content_file,
							section_id: payload.lesson.sectionId,
							is_locked: payload.lesson.isLocked,
							lesson_number: payload.lesson.lessonNumber,
							lesson_type: payload.lesson.lessonType,
							estimated_time: payload.lesson.estimatedTime
						};
					}
					if (lessonId && isContent)  {
						update = {
							title: payload.lesson.title,
							content: payload.lesson.content,
							section_id: payload.lesson.sectionId,
							is_locked: payload.lesson.isLocked,
							lesson_number: payload.lesson.lessonNumber,
							lesson_type: payload.lesson.lessonType,
							estimated_time: payload.lesson.estimatedTime
						};
					}
					const res = yield call(ADMIN_API.updateLessonById, lessonId, update, {token: authTokens.token});
					if (res && payload.lesson.lessonHasQuiz) {
						if (payload?.quizzes) {
							quizData = {
								quizzes: payload?.quizzes
							}
						}
						yield call(ADMIN_API.updateQuizById, quizData, {token: authTokens.token});
						yield put(clearTempQuiz());
					}
					yield put(setRequestStatus({
						success: true,
						status: 'success',
						type: 'lesson',
						isError: false,
						message: res.message
					}));
				}
				yield put(setLoading(false));

			} catch (e) {
				console.log(e);
				yield put(setLoading(false));
				yield put(setRequestStatus({
					success: false,
					type: 'lesson',
					status: 'error',
					isError: true,
					message: e.message
				}));
			}
			break;
		}
		case DELETE_LESSON: {
			try {
				yield initRequest();
		
				const authTokens = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
				if (authTokens) {
					const lessonId = payload;
					yield call(ADMIN_API.deleteLessonById, lessonId, {token: authTokens.token});
				}
				yield put(setLoading(false));

			} catch (e) {
				console.log(e);
				yield put(setLoading(false));
				yield put(setRequestStatus({
					success: false,
					type: 'lesson',
					status: 'error',
					isError: true,
					message: e.message
				}));
			}
			break;
		}
		default: {
			throw new Error('Unexpected action type.');
		}
	}
}

export default courseSaga;
