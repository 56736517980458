import React, { Suspense } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import LoadingScreen from '../../components/LoadingScreen';

const KycUpgradeComponent = React.lazy(() => import('./components/KycUpgrade'));

const KycUpgrade = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const store = useSelector((state) => ({
	  parentsList: state.app.parentsList
  }), shallowEqual);

  const parent = store.parentsList.find((parent) => id === parent.id);

  return (
    <>
      {parent && (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
          <KycUpgradeComponent 
            parentId={id} 
            parent={parent}
          />
        </Suspense>
      )}
    </>
  );
}

export default KycUpgrade;