import { useCallback, useEffect, useState } from 'react';

import * as ROUTE from '../constants/routes'
import useDidMount from './useDidMount';

const useCourses = () => {
  const [coursesList, setCoursesList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const isDidMount = useDidMount();

  const fetchCoursesList = useCallback(async () => {
    try {
      const authToken = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
      const requestOptions = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken.token}`
        }
      }
      if (coursesList.length === 0 && isDidMount) {
        setLoading(true);
        setError('');
        const _courses =  await fetch(`${ROUTE.ADMIN_API}/courses`, requestOptions)
          .then(res => res.json())
          .then(data => {
          return data
        })
        .catch(err => console.log(err))
        if (!_courses?.data) {
          if (isDidMount) {
            setError('No course found.');
            setLoading(false);
          }
        } else {
          const data = _courses;
          if (isDidMount) {
            setLoading(false);
            setCoursesList(data);
          }
        }
      }
    } catch (e) {
      if (isDidMount) {
        setError('Failed to fetch courses');
        setLoading(false);
      }
    }
  }, [isDidMount, coursesList]);

  useEffect(() => {
    fetchCoursesList();
  }, [fetchCoursesList]);

  return {
    fetchCoursesList, coursesList, 
    isLoading, error
  };
};

export default useCourses;
