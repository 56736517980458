import { useCallback, useEffect, useState } from 'react';

import * as ROUTE from '../constants/routes'
import useDidMount from './useDidMount';

const useUsers = (userId=null) => {
  const [parentsList, setparentsList] = useState([]);
  const [kidsList, setkidsList] = useState([]);
  const [userCount, setUserCount] = useState([]);
  const [userCode, setUserCode] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const isDidMount = useDidMount();

  const fetchParentsList = useCallback(async () => {
    try {
      setLoading(true);
      setError('');
      const authToken = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
      const requestOptions = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken.token}`
        }
      }
      if (parentsList.length === 0 && isDidMount) {
        const _parents =  await fetch(`${ROUTE.ADMIN_API}/users/parents`, 
          requestOptions)
        .then(res => res.json())
        .then(data =>{
          return data
        })
        .catch(err => console.log(err))
        if (!_parents?.data) {
          if (isDidMount) {
            setError('No parent found.');
            setLoading(false);
          }
        } else {
          const data = _parents;
          if (isDidMount) {
            setLoading(false);
            setparentsList(data);
          }
        }
      }
    } catch (e) {
      if (isDidMount) {
        setError('Failed to fetch parents');
        setLoading(false);
      }
    }
  }, [isDidMount, parentsList]);

  const fetchKidsList = useCallback(async () => {
    try {
      setLoading(true);
      setError('');
      const authToken = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
      const requestOptions = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken.token}`
        }
      }
      if (kidsList.length === 0 && isDidMount) {
        const _kids =  await fetch(`${ROUTE.ADMIN_API}/users/kids`, 
          requestOptions)
        .then(res => res.json())
        .then(data =>{
          return data
        })
        .catch(err => console.log(err))
        if (!_kids?.data) {
          if (isDidMount) {
            setError('No parent found.');
            setLoading(false);
          }
        } else {
          const data = _kids;
          if (isDidMount) {
            setLoading(false);
            setkidsList(data);
          }
        }
      }
    } catch (e) {
      if (isDidMount) {
        setError('Failed to fetch parents');
        setLoading(false);
      }
    }
  }, [isDidMount, kidsList]);

  useEffect(() => {
      fetchParentsList();
      fetchKidsList();
  }, [fetchParentsList, fetchKidsList]);

  const fetchUserCount = useCallback(async () => {
    try {
      setLoading(true);
      setError('');
      const authToken = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
      const requestOptions = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken.token}`
        }
      }
      if (userCount.length === 0 && isDidMount) {
        const _userCount =  await fetch(`${ROUTE.ADMIN_API}/users/count`, 
          requestOptions)
        .then(res => res.json())
        .then(data =>{
          return data
        })
        .catch(err => console.log(err))
        if (!_userCount?.data) {
          if (isDidMount) {
            setError('No user found.');
            setLoading(false);
          }
        } else {
          const data = _userCount;
          if (isDidMount) {
            setLoading(false);
            setUserCount(data.data);
          }
        }
      }
    } catch (e) {
      if (isDidMount) {
        setError('Failed to fetch user count');
        setLoading(false);
      }
    }
  }, [isDidMount, userCount]);

  const fetchUserCode = useCallback(async () => {
    try {
      setLoading(true);
      setError('');
      const authToken = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
      const requestOptions = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken.token}`
        },
        body: JSON.stringify(
					{
						"user_id": userId,
					}
				)
      }
      if (userCode.length === 0 && isDidMount) {
        const _userCode =  await fetch(`${ROUTE.ADMIN_API}/users/codes`, 
          requestOptions)
          .then(res => res.json())
          .then(data =>{
            return data
          })
          .catch(err => console.log(err))

        if (!_userCode?.data) {
          if (isDidMount) {
            setError('No code found.');
            setLoading(false);
          }
        } else {
          const data = _userCode;
          if (isDidMount) {
            setLoading(false);
            setUserCode(data.data);
          }
        }
      }
    } catch (e) {
      if (isDidMount) {
        setError('Failed to fetch user code');
        setLoading(false);
      }
    }
  }, [isDidMount, userCode, userId]);

  return {
    fetchParentsList, fetchKidsList, fetchUserCount,
    fetchUserCode, parentsList, kidsList, userCount,
    userCode, isLoading, error
  };
};

export default useUsers;
