import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';

import LoadingScreen from '../../../components/LoadingScreen';

const LessonCourseSectionsComponent = React.lazy(() => import('./components/LessonCourseSectionsList'));

const LessonCourseSectionsList = () => {
  const { pathname } = useLocation();

  return (
    <div>
      <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
        <LessonCourseSectionsComponent />
      </Suspense>
    </div>
  );
};

export default LessonCourseSectionsList;
