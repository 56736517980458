import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as ROUTE from '../constants/routes'
import useDidMount from './useDidMount';
import { setTempQuiz } from '../redux/actions/miscActions';

const useLesson = () => {
  const [lessonList, setLessonList] = useState([]);
  const [quizzesList, setQuizzesList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const isDidMount = useDidMount();
  const dispatch = useDispatch();

  const fetchLessonList = useCallback(async () => {
    try {
      const authToken = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
      const requestOptions = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken.token}`
        }
      }
      if (lessonList.length === 0 && isDidMount) {
        setLoading(true);
        setError('');
        const _lessons =  await fetch(`${ROUTE.ADMIN_API}/courses/lessons`, 
          requestOptions)
        .then(res => res.json())
        .then(data =>{
          return data
        })
        .catch(err => console.log(err))
        if (!_lessons?.data) {
          if (isDidMount) {
            setError('No lesson found.');
            setLoading(false);
          }
        } else {
          const data = _lessons;
          if (isDidMount) {
            setLoading(false);
            setLessonList(data);
          }
        }
      }
    } catch (e) {
      if (isDidMount) {
        console.log(e)
        setError('Failed to fetch lessons');
        setLoading(false);
      }
    }
  }, [isDidMount, lessonList]);


  const fetchQuizzesByLessonId = useCallback(async (lessonId) => {
    try {
      const authToken = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
      const requestOptions = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken.token}`
        }
      }
      if (quizzesList.length === 0 && isDidMount) {
        setLoading(true);
        setError('');
        const _quizzes =  await fetch(`${ROUTE.ADMIN_API}/courses/lessons/quiz?lesson_id=${lessonId}`, 
          requestOptions)
        .then(res => res.json())
        .then(data =>{
          return data
        })
        .catch(err => console.log(err))
        if (!_quizzes?.data) {
          if (isDidMount) {
            setError('No quiz found.');
            setLoading(false);
          }
        } else {
          const data = _quizzes;
          if (isDidMount) {
            setLoading(false);
            setQuizzesList(data);

            const quizzes = data?.data.map((quiz) => ({
              quiz: { 
                "id": quiz.id,
                "uuid": quiz.id,
                "question": quiz.question || "",
                "time": quiz.time || "",
                "question_number": quiz.question_number || "",
                "options": {
                  "option_1": {"id": quiz.options[0]?.id, "option": quiz.options[0]?.option || "", "is_answer": quiz.options[0]?.is_answer || false},
                  "option_2": {"id": quiz.options[1]?.id, "option": quiz.options[1]?.option || "", "is_answer": quiz.options[1]?.is_answer || false},
                  "option_3": {"id": quiz.options[2]?.id, "option": quiz.options[2]?.option || "", "is_answer": quiz.options[2]?.is_answer || false},
                  "option_4": {"id": quiz.options[3]?.id, "option": quiz.options[3]?.option || "", "is_answer": quiz.options[3]?.is_answer || false},
                  "option_5": {"id": quiz.options[4]?.id, "option": quiz.options[4]?.option || "", "is_answer": quiz.options[4]?.is_answer || false}
                }
              },
              rowOrder: {
                id: quiz.id
              }
            }))
            setQuizzesList(data);
            if (quizzes) {
              quizzes.map((quiz) => (
                dispatch(setTempQuiz(quiz.quiz, quiz.rowOrder))
              ))
            }
          }
        }
      }
    } catch (e) {
      if (isDidMount) {
        console.log(e)
        setError('Failed to fetch quizzes');
        setLoading(false);
      }
    }
  }, [dispatch, isDidMount, quizzesList]);

  useEffect(() => {
    fetchLessonList()
  }, [fetchLessonList]);

  return {
    fetchLessonList, fetchQuizzesByLessonId, 
    lessonList, quizzesList, isLoading, error
  };
};

export default useLesson;