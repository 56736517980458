import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { 
  Box,
  Card, Grid, Stack 
} from '@mui/material';
// routes
import * as ROUTES from '../../../../constants/routes';
import { FormProvider, RHFSelect, RHFTextField } from '../../../../components/hook-form';
import { createSection } from '../../../../redux/actions/courseActions';
import { setRequestStatus } from '../../../../redux/actions/miscActions';
import { useCourses } from '../../../../hooks';

// ----------------------------------------------------------------------

export default function CreateSectionForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const CreateCourseSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required'),
    courseId: Yup.string()
      .required('Course is required'),
    sectionNumber: Yup.number()
      .default(1)
      .required('Section is required')
  });

  const { coursesList } = useCourses();
  const courses = coursesList?.data ? coursesList?.data?.data.map((course) => ({
    id: course?.id,
	  courseTitle: course?.title
  })) : [];

  const defaultValues = useMemo(
    () => ({
      title: '',
      courseId: '',
      sectionNumber: ''
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(CreateCourseSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const { requestStatus, isLoading } = useSelector((state) => ({
    requestStatus: state.app.requestStatus,
    isLoading: state.app.loading
  }));

  useEffect(() => {
    dispatch(setRequestStatus(null));
    if (requestStatus?.message && !isLoading) {
      enqueueSnackbar(requestStatus.message, { variant: requestStatus.status })
      if (!requestStatus?.isError) {
        navigate(ROUTES.PATH_ADMIN.courses.createLessons);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStatus, isLoading]);

  const onSubmit = (form) => {
    dispatch(createSection({
      title: form.title.trim(),
      courseId: form.courseId,
      sectionNumber: form.sectionNumber
    }));
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="title" label="Section Title" />
              <RHFSelect name="courseId" label="Course" placeholder="Course">
                <option value="" />
                {courses.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.courseTitle}
                  </option>
                ))}
              </RHFSelect>
              <RHFTextField name="sectionNumber" label="Section Number" />
            </Box>
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isLoading}>
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
