import { useEffect } from 'react';
import numeral from 'numeral';
// @mui
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
// sections
import {
  AppWidgetSummary
} from '../../sections/@dashboard/app';
import { useSettings, useUsers, useWallets, useGoal, useTransactions } from '../../hooks';
import { WidgetSummary } from '../../sections/@dashboard/general/app';
import { fNairaEquivalent } from '../../utils/formatNumber';
import { useAdminWallet } from '../../hooks/useAdminWallet';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { themeStretch } = useSettings();
  const {fetchUserCount, userCount } = useUsers();
  const {
    fetchWalletCount, fetchWalletBalance, 
    walletCount, walletBalance 
  } = useWallets();
  const {
    fetchGoalBalance, goalBalance 
  } = useGoal();
  const {
    fetchTransactionStat, transactionStat 
  } = useTransactions({pageNumber: 1});

  const { adminWallet } = useAdminWallet();

  useEffect(() => {
    fetchUserCount();
    fetchWalletCount();
    fetchWalletBalance();
    fetchGoalBalance();
    fetchTransactionStat();
  }, [fetchUserCount, fetchWalletCount, fetchWalletBalance, fetchGoalBalance, fetchTransactionStat]);

  return (
    <Page title="Dashboard">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Total Active Users"
              percent={100}
              total={userCount?.users ? userCount.users : 0}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Total Parents"
              percent={numeral(userCount.parents/userCount.users*100).format()}
              total={userCount?.parents ? userCount.parents : 0}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Total Kids"
              percent={numeral(userCount.kids/userCount.users*100).format()}
              total={userCount?.kids ? userCount.kids : 0}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <WidgetSummary title="Admin Balance" total={fNairaEquivalent(adminWallet ? adminWallet.balance : 0)} />
          </Grid>

          <Grid item xs={12} md={4}>
            <WidgetSummary title="Custodial Balance" total={fNairaEquivalent(walletBalance?.custodial ? walletBalance.custodial : 0)} />
          </Grid>

          <Grid item xs={12} md={4}>
            <WidgetSummary title="Parents Balance" total={fNairaEquivalent(walletBalance?.parents ? walletBalance.parents : 0)} />
          </Grid>

          <Grid item xs={12} md={4}>
            <WidgetSummary title="Kids Balance" total={fNairaEquivalent(walletBalance?.kids ? walletBalance.kids : 0)} />
          </Grid>

          <Grid item xs={12} md={4}>
            <WidgetSummary title="Custodial Wallet" total={walletCount?.custodial ? walletCount.custodial : 0} />
          </Grid>

          <Grid item xs={12} md={4}>
            <WidgetSummary title="Parents Wallet" total={walletCount?.parents ? walletCount.parents : 0} />
          </Grid>

          <Grid item xs={12} md={4}>
            <WidgetSummary title="Kids Wallet" total={walletCount?.kids ? walletCount.kids : 0} />
          </Grid>

          <Grid item xs={12} md={4}>
            <WidgetSummary title="Goals balance" total={fNairaEquivalent(goalBalance)} />
          </Grid>

          <Grid item xs={12} md={4}>
            <WidgetSummary title="Total inflow" total={fNairaEquivalent(transactionStat?.total_inflow ? transactionStat.total_inflow : 0)} />
          </Grid>

          <Grid item xs={12} md={4}>
            <WidgetSummary title="Total outflow" total={fNairaEquivalent(transactionStat?.total_outflow ? transactionStat.total_outflow : 0)} />
          </Grid>

        </Grid>
      </Container>
    </Page>
  );
}
