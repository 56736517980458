import CryptoJS from 'crypto-js';
// ----------------------------------------------------------------------

export function encryptUrlId(urlId) {
  const secretKey = process.env.REACT_APP_URL_ID_CRYPTO_KEY;
  // Encrypt
  const encryptedUrlId = CryptoJS.AES.encrypt(JSON.stringify(urlId), secretKey).toString();

  return encodeURIComponent(encryptedUrlId);
}

export function decryptUrlId(cipherUrlId) {
  const secretKey = process.env.REACT_APP_URL_ID_CRYPTO_KEY;
  // Decrypt
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(cipherUrlId), secretKey);
  const decryptedUrlId = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return decryptedUrlId;
}
