import React, { Suspense } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import LoadingScreen from '../../components/LoadingScreen';
import { decryptUrlId } from '../../utils/helpers';

const UpdateCourseComponent = React.lazy(() => import('./components/UpdateCourse'));

const UpdateCourse = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const store = useSelector((state) => ({
    courses: state.course.courses
  }), shallowEqual);
  
  const decryptedUrlId = decryptUrlId(id);
  const course = store.courses.find((course) => decryptedUrlId === course.id);
  return (
    <>
      {course && (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
          <UpdateCourseComponent 
            course={course}
          />
        </Suspense>
      )}
    </>
  );
}

export default UpdateCourse;