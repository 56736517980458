import React, { Suspense } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import LoadingScreen from '../../../components/LoadingScreen';
import { decryptUrlId } from '../../../utils/helpers';

const UpdateCategoryComponent = React.lazy(() => import('./components/UpdateCategory'));

const UpdateCategory = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const store = useSelector((state) => ({
    categories: state.course.categories
  }), shallowEqual);

  const decryptedUrlId = decryptUrlId(id);
  const category = store.categories.find((category) => decryptedUrlId === category.id);

  return (
    <>
      {category && (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
          <UpdateCategoryComponent category={category} />
        </Suspense>
      )}
    </>
  );
}

export default UpdateCategory;