// @mui
import { Container } from '@mui/material';
// routes
import * as ROUTES from '../../../constants/routes';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// sections
import CreateCategoryForm from '../../../sections/@dashboard/course/category/CreateCategoryForm';

// ----------------------------------------------------------------------

export default function CreateCategory() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Create Category">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Create Category'
          links={[
            { name: 'Dashboard', href: ROUTES.PATH_ADMIN.root },
            { name: 'Category', href: ROUTES.PATH_ADMIN.courses.category },
            { name: 'Create' },
          ]}
        />

        <CreateCategoryForm />
      </Container>
    </Page>
  );
}
