import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import LoadingScreen from '../../../components/LoadingScreen';

const CreditWalletComponent = React.lazy(() => import('./components/CreditWallet'));

const CreditWallet = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const { walletsList } = useSelector((state) => ({
    walletsList: state.wallet
  }));

  const _wallets = walletsList?.map((parent) => ({
    id: parent?.id,
	  name: parent?.name,
    acctNo: parent?.acctNo,
    provider: parent?.provider,
    bankName: parent?.bankName,
    currency: parent?.currency,
    walletType: parent?.type,
    balance: parent?.walletType ,
    parentId: parent?.parentId,
    status: parent?.status,
  }));

  return (
    <>
      {_wallets && (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
          <CreditWalletComponent 
            walletId={id} 
            walletList={_wallets}
          />
        </Suspense>
      )}
    </>
  );
}

export default CreditWallet;