// highlight
import './utils/highlight';
// scroll bar
import 'simplebar/src/simplebar.css';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

//
import App from './App';
import configureStore from './redux/store/store';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { onAuthStateFail, onAuthStateSuccess } from './redux/actions/authActions';

// ----------------------------------------------------------------------

// Sentry.init({
//   dsn: "https://7d0e9ccb25774cd6bbc522930cec75d1@o30126.ingest.sentry.io/4505000229011456",
//   integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const { store, persistor } = configureStore();
const root = ReactDOM.createRoot(document.getElementById('root'));
const authToken = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
const user = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_USER));

if (user && authToken) {
  store.dispatch(onAuthStateSuccess(user));
} else {
  store.dispatch(onAuthStateFail('Failed to authenticate'));
}

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <App store={store} persistor={persistor} />
          </BrowserRouter>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to enable client cache, register instead.
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
