import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { 
  Paper, ClickAwayListener, 
  Stack, Typography, FormControlLabel, Switch
} from '@mui/material';
import { 
  RHFTextField, RHFEditor
} from '../../../../components/hook-form';

// utils
import uuidv4 from '../../../../utils/uuidv4';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

AddQuiz.defaultProps = {
  lessonId: ""
};

AddQuiz.propTypes = {
  lessonId: PropTypes.string,
  onAddQuiz: PropTypes.func,
  onCloseAddQuiz: PropTypes.func
};

export default function AddQuiz({ lessonId, onAddQuiz, onCloseAddQuiz }) {
  const [newQuestion, setNewQuestion] = useState('');
  const [newQuizEstimatedTime, setNewQuizEstimatedTime] = useState('');
  const [newQuestionNumber, setNewQuestionNumber] = useState('');
  const [newOption, setNewOption] = useState({
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    option5: '',
  });
  const [newIsAnswer, setNewIsAnswer] = useState({
    answer1: false,
    answer2: false,
    answer3: false,
    answer4: false,
    answer5: false,
  });

  const optionsMapping = [
    { optionValue: "option1", optionLabel: "Option 1", answerValue: "answer1", answerState: newIsAnswer.answer1 },
    { optionValue: "option2", optionLabel: "Option 2", answerValue: "answer2", answerState: newIsAnswer.answer2 },
    { optionValue: "option3", optionLabel: "Option 3", answerValue: "answer3", answerState: newIsAnswer.answer3 },
    { optionValue: "option4", optionLabel: "Option 4", answerValue: "answer4", answerState: newIsAnswer.answer4 },
    { optionValue: "option5", optionLabel: "Option 5", answerValue: "answer5", answerState: newIsAnswer.answer5 }
  ];

  const handleQuizOption = (event) => {
    setNewOption({
      ...newOption,
      [event.target.name]: event.target.value,
    });
  };

  const handleQuizAnswer = (event) => {
    setNewIsAnswer({
      ...newIsAnswer,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClickAddTask = () => {
    if (newQuestionNumber) {
      const _id = uuidv4();
      let _quiz = null
      if (lessonId) {
        _quiz = {
          "uuid": _id,
          "lesson_id": lessonId,
          "question": newQuestion,
          "time": newQuizEstimatedTime,
          "question_number": newQuestionNumber,
          "options": {
            "option_1": {"option": newOption.option1, "is_answer": newIsAnswer.answer1},
            "option_2": {"option": newOption.option2, "is_answer": newIsAnswer.answer2},
            "option_3": {"option": newOption.option3, "is_answer": newIsAnswer.answer3},
            "option_4": {"option": newOption.option4, "is_answer": newIsAnswer.answer4},
            "option_5": {"option": newOption.option5, "is_answer": newIsAnswer.answer5}
          }
        }
      } else {
        _quiz = {
          "uuid": _id,
          "question": newQuestion,
          "time": newQuizEstimatedTime,
          "question_number": newQuestionNumber,
          "options": {
            "option_1": {"option": newOption.option1, "is_answer": newIsAnswer.answer1},
            "option_2": {"option": newOption.option2, "is_answer": newIsAnswer.answer2},
            "option_3": {"option": newOption.option3, "is_answer": newIsAnswer.answer3},
            "option_4": {"option": newOption.option4, "is_answer": newIsAnswer.answer4},
            "option_5": {"option": newOption.option5, "is_answer": newIsAnswer.answer5}
          }
        }
      }

      onAddQuiz({
        quiz: _quiz,
        rowOrder: {
          id: _id
        }
      });
    }
    onCloseAddQuiz();
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAddTask}>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <form>
            <LabelStyle>Quiz</LabelStyle>
            <Stack spacing={3} mt={2}>
              <RHFTextField 
                name="newQuestionNumber"
                value={newQuestionNumber}
                label="Question Number" 
                onChange={(event) => setNewQuestionNumber(event.target.value)}
              />
              <RHFEditor simple 
                name="newQuestion"
                value={newQuestion}
                onChange={(event) => setNewQuestion(event)}
              />
              <RHFTextField 
                name="newQuizEstimatedTime" label="Estimated Time (mins)" 
                value={newQuizEstimatedTime}
                onChange={(event) => setNewQuizEstimatedTime(event.target.value)}
              />
              {optionsMapping.map((option) => (
                <>
                  <RHFTextField 
                    name={option.optionValue} 
                    label={option.optionLabel}
                    key={option.optionValue}
                    onChange={handleQuizOption}
                  />
                  <FormControlLabel
                    control={
                      <Switch 
                        checked={option.answerState} 
                        onChange={handleQuizAnswer} 
                        name={option.answerValue}
                        sx={{ m: 0 }} />
                    }
                    label="Answer"
                  />
                </>
              ))}
            </Stack>
          </form>
        </Paper>
      </ClickAwayListener>
    </>
  );
}