import React from 'react';
import { Routes, Route } from 'react-router-dom';

import {
  CategoryList,
  CoursesList,
  CreateCategory,
  CreateChildWallet,
  CreateCourse,
  CreateLesson,
  CreateSection,
  CreditWallet,
  DashboardApp,
  ChildrenList,
  KycUpgrade,
  KycUpgrade2,
  LessonList,
  LessonCoursesList,
  LessonCourseSectionsList,
  Login,
  Page404,
  ParentsList,
  Register,
  SectionList,
  TransactionsList,
  UpdateCategory,
  UpdateCourse,
  UpdateLesson,
  UpdateSection,
  VerifyCode,
  ViewChild,
  ViewParent,
  ViewWallet,
  WalletsList,
  ChildWalletsList,
  PhoneCodeList,
  EmailCodeList,
  SearchKids,
  SearchWallet,
  SearchParents,
} from '../pages';

import * as ROUTES from '../constants/routes';
import PublicRoute from './PublicRoute';
import AdminRoute from './AdminRoute';
import ClientRoute from './ClientRoute';

const AppRouter = () => (
  <>
    <Routes>
      <Route
        path={ROUTES.PATH_AUTH.signIn}
        element={<PublicRoute component={Login} path={ROUTES.PATH_AUTH.signIn} />}
      />
      <Route
        path={ROUTES.PATH_AUTH.signUp}
        element={<PublicRoute component={Register} path={ROUTES.PATH_AUTH.signUp} />}
      />
      <Route
        path={ROUTES.PATH_AUTH.verifyCode}
        element={<ClientRoute component={VerifyCode} path={ROUTES.PATH_AUTH.verifyCode} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.users.searchChildren}
        element={<AdminRoute component={SearchKids} path={ROUTES.PATH_ADMIN.users.searchChildren} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.users.searchParent}
        element={<AdminRoute component={SearchParents} path={ROUTES.PATH_ADMIN.users.searchParent} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.users.searchWallet}
        element={<AdminRoute component={SearchWallet} path={ROUTES.PATH_ADMIN.users.searchWallet} />}
      />
      <Route
        path={ROUTES.ADMIN_DASHBOARD}
        element={<AdminRoute component={DashboardApp} path={ROUTES.PATH_ADMIN.root} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.root}
        element={<AdminRoute component={DashboardApp} path={ROUTES.PATH_ADMIN.root} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.users.parent}
        element={<AdminRoute component={ParentsList} path={ROUTES.PATH_ADMIN.users.parent} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.users.viewParent}
        element={<AdminRoute component={ViewParent} path={ROUTES.PATH_ADMIN.users.viewParent} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.users.kycUpgrade1}
        element={<AdminRoute component={KycUpgrade} path={ROUTES.PATH_ADMIN.users.kycUpgrade1} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.users.kycUpgrade2}
        element={<AdminRoute component={KycUpgrade2} path={ROUTES.PATH_ADMIN.users.kycUpgrade2} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.users.children}
        element={<AdminRoute component={ChildrenList} path={ROUTES.PATH_ADMIN.users.children} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.users.viewChild}
        element={<AdminRoute component={ViewChild} path={ROUTES.PATH_ADMIN.users.viewChild} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.users.phoneCode}
        element={<AdminRoute component={PhoneCodeList} path={ROUTES.PATH_ADMIN.users.phoneCode} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.users.emailCode}
        element={<AdminRoute component={EmailCodeList} path={ROUTES.PATH_ADMIN.users.emailCode} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.transactions.root}
        element={<AdminRoute component={TransactionsList} path={ROUTES.PATH_ADMIN.transactions.root} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.wallet.parent}
        element={<AdminRoute component={WalletsList} path={ROUTES.PATH_ADMIN.wallet.parent} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.wallet.children}
        element={<AdminRoute component={ChildWalletsList} path={ROUTES.PATH_ADMIN.wallet.children} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.wallet.credit}
        element={<AdminRoute component={CreditWallet} path={ROUTES.PATH_ADMIN.wallet.credit} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.wallet.view}
        element={<AdminRoute component={ViewWallet} path={ROUTES.PATH_ADMIN.wallet.view} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.wallet.createChildWallet}
        element={<AdminRoute component={CreateChildWallet} path={ROUTES.PATH_ADMIN.wallet.createChildWallet} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.courses.root}
        element={<AdminRoute component={CoursesList} path={ROUTES.PATH_ADMIN.courses.root} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.courses.create}
        element={<AdminRoute component={CreateCourse} path={ROUTES.PATH_ADMIN.courses.create} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.courses.update}
        element={<AdminRoute component={UpdateCourse} path={ROUTES.PATH_ADMIN.courses.update} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.courses.category}
        element={<AdminRoute component={CategoryList} path={ROUTES.PATH_ADMIN.courses.root} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.courses.createCategory}
        element={<AdminRoute component={CreateCategory} path={ROUTES.PATH_ADMIN.courses.createCategory} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.courses.updateCategory}
        element={<AdminRoute component={UpdateCategory} path={ROUTES.PATH_ADMIN.courses.updateCategory} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.courses.sections}
        element={<AdminRoute component={SectionList} path={ROUTES.PATH_ADMIN.courses.sections} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.courses.createSection}
        element={<AdminRoute component={CreateSection} path={ROUTES.PATH_ADMIN.courses.createSection} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.courses.updateSection}
        element={<AdminRoute component={UpdateSection} path={ROUTES.PATH_ADMIN.courses.updateSection} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.courses.lessonsCourses}
        element={<AdminRoute component={LessonCoursesList} path={ROUTES.PATH_ADMIN.courses.lessonsCourses} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.courses.lessonsCourseSections}
        element={
          <AdminRoute component={LessonCourseSectionsList} path={ROUTES.PATH_ADMIN.courses.lessonsCourseSections} />
        }
      />
      <Route
        path={ROUTES.PATH_ADMIN.courses.lessonsSections}
        element={<AdminRoute component={LessonList} path={ROUTES.PATH_ADMIN.courses.lessonsSections} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.courses.createLessons}
        element={<AdminRoute component={CreateLesson} path={ROUTES.PATH_ADMIN.courses.createLessons} />}
      />
      <Route
        path={ROUTES.PATH_ADMIN.courses.updateLesson}
        element={<AdminRoute component={UpdateLesson} path={ROUTES.PATH_ADMIN.courses.updateLesson} />}
      />
      <Route path={ROUTES.PAGE404} element={<Page404 />} />
    </Routes>
  </>
);

export default AppRouter;
