import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import Divider from '@mui/material/Divider';
import { 
  Box, Button,
  Card, Grid, Stack, Typography 
} from '@mui/material';

import { useSection } from '../../../../hooks';
import * as ROUTES from '../../../../constants/routes';
import { IS_COURSE_LOCKED, LESSON_TYPE_TYPE } from '../../../../constants/constants';
import { 
  FormProvider, RHFRadioGroup, RHFSelect, 
  RHFTextField, RHFSwitch, RHFUploadMultiVideoFile
} from '../../../../components/hook-form';
import Iconify from '../../../../components/Iconify';
import { createLesson } from '../../../../redux/actions/courseActions';
import { 
  clearTempQuiz, setRequestStatus, 
  setTempQuiz 
} from '../../../../redux/actions/miscActions';
import { AddQuiz, AddedQuiz } from '../quiz';

// ----------------------------------------------------------------------

export default function CreateLessonForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [ open, setOpen ] = useState(false);
  const { sectionList, sectionIsLoading } = useSection();
  const { tempQuiz } = useSelector((state) => state.app);

  const CreateLessonSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required'),
    isContent: Yup.boolean(),
    isContentUrl: Yup.boolean(),
    isContentFile: Yup.boolean(),
    hasQuiz: Yup.boolean(),
    content: Yup.string().when('isContent', {
			is: true,
			then: (CreateLessonSchema) => 
      CreateLessonSchema.required('Content is required.')
		}),
    contentUrl: Yup.string(),
    contentFile: Yup.mixed(),
    sectionId: Yup.string()
      .required('Course section is required'),
    isLocked: Yup.mixed()
      .default(0),
    lessonNumber: Yup.number()
      .moreThan(0, 'Number should be greater than 0'),
    lessonType: Yup.mixed()
      .required('Lesson type is required')
      .oneOf(LESSON_TYPE_TYPE),
    estimatedTime: Yup.number()
      .moreThan(0, 'Mins should be greater than 0')
      .required('Estimated time is required'),
  });

  const lessonType = [
    {
      id: LESSON_TYPE_TYPE[0],
      value: 'Video'
    },
    {
      id: LESSON_TYPE_TYPE[1],
      value: 'Article'
    }
  ];

  const handleOpenAddQuiz = () => {
    setOpen((prev) => !prev);
  };

  const handleCloseAddQuiz = () => {
    setOpen(false);
  };

  const onClearAllQuizes = () => {
    dispatch(clearTempQuiz());
    enqueueSnackbar("Quizes cleared!", { variant: "info" })
  }

  const handleAddQuiz = (_quiz) => {
    dispatch(setTempQuiz(_quiz.quiz, _quiz.rowOrder));
    handleCloseAddQuiz();
  };

  const defaultValues = useMemo(
    () => ({
      title: '',
      isContent: false,
      content: '',
      isContentUrl: false,
      contentUrl: '',
      isContentFile: false,
      contentFile: '',
      sectionId: '',
      isLocked: 0,
      hasQuiz: false,
      lessonNumber: '',
      lessonType: '',
      estimatedTime: ''
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(CreateLessonSchema),
    defaultValues,
  });

  const { watch, handleSubmit, getValues, setValue } = methods;
  const values = watch();

  const hasVideo = values.lessonType === LESSON_TYPE_TYPE[0];
  const hasArticle = values.lessonType === LESSON_TYPE_TYPE[1];
  const hasQuiz = values.hasQuiz === true;

  const { requestStatus, isLoading } = useSelector((state) => ({
    requestStatus: state.app.requestStatus,
    isLoading: state.app.loading
  }));

  useEffect(() => {
    dispatch(setRequestStatus(null));
    dispatch(clearTempQuiz());
    if (requestStatus?.message && !isLoading) {
      enqueueSnackbar(requestStatus.message, { variant: requestStatus.status })
      if (!requestStatus?.isError) {
        navigate(ROUTES.PATH_ADMIN.courses.lessonsCourses);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStatus, isLoading]);


  useEffect(() => {
    if (hasVideo) {
      setValue('isContent', false);
      setValue('isContentUrl', true);
      setValue('isContentFile', true);
    }
    if (hasArticle) {
      setValue('isContent', true);
      setValue('isContentUrl', false);
      setValue('isContentFile', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, hasVideo, hasArticle]);

  const onSubmit = (form) => {    
    dispatch(createLesson({
      lesson: {
        title: form.title.trim(),
        content: form.content.trim(),
        contentUrl: form.contentUrl,
        contentFile: getValues('base64ContentFile'),
        sectionId: form.sectionId,
        isLocked: form.isLocked,
        lessonNumber: form.lessonNumber,
        lessonType: form.lessonType.toUpperCase(),
        estimatedTime: form.estimatedTime,
        lessonHasQuiz: hasQuiz
      },
      quizzes:  tempQuiz?.rowOrder.map((rowId) => (
                    tempQuiz.quiz[rowId]
                  )),
    }));
  };

  const handleRemove = (file) => {
    const filteredItems = values.contentFile?.filter((_file) => _file !== file);
    setValue('contentFile', filteredItems);
    setValue('base64ContentFile', '');
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const video = acceptedFiles[0];
      const reader = new FileReader();
      // set base64 url
      if (video) {
        reader.readAsDataURL(video);
        reader.addEventListener('load', (e) => {
          setValue('base64ContentFile', e.target.result);
        });
      }
      // set value for preview
      setValue(
        'contentFile',
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    [setValue]
  );

  const _sections = sectionList?.data?.length >= 1 && !sectionIsLoading ? 
    sectionList?.data.map((section) => ({
      id: section?.id,
      title: section?.title
    })) 
  : [];

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
          <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="title" label="Lesson Title" />
              <RHFTextField name="lessonNumber" label="Lesson Number" />
              <RHFSelect name="lessonType" label="Lesson Type" placeholder="Lesson Type">
                <option value="" />
                {lessonType.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </RHFSelect>
              <RHFSelect name="sectionId" label="Sections" placeholder="Sections">
                <option value="" />
                {_sections.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.title}
                  </option>
                ))}
              </RHFSelect>
              <RHFTextField name="estimatedTime" label="Estimated Time (mins)" />
              <div>
                <Typography variant="subtitle1">Lock Course</Typography>
                <RHFRadioGroup
                  name="isLocked"
                  options={IS_COURSE_LOCKED.map((item) => item.id)}
                  getOptionLabel={IS_COURSE_LOCKED.map((item) => item.value)}
                  sx={{
                    '& .MuiFormControlLabel-root': { mr: 2 },
                  }}
                />
              </div>
            </Box>
            {hasArticle && (
              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <RHFTextField name="content" label="Content" multiline rows={3} />
              </Stack>
            )}
            {hasVideo && (
              <Box sx={{ mt: 3 }}>
                <RHFTextField name="contentUrl" label="Video URL" />
                <Divider variant="middle" sx={{ mt: 2, mb: 2 }}>
                  <Typography
                    color="text.secondary"
                    display="block"
                    variant="caption"
                  >
                    OR
                  </Typography>
                </Divider>
                <RHFUploadMultiVideoFile
                  name="contentFile"
                  showPreview
                  accept=".mov,.mp4"
                  onDrop={handleDrop}
                  onRemove={handleRemove}
                />
              </Box>
            )}
            <RHFSwitch name="hasQuiz" label="Has quiz" />
            {!hasQuiz && (
              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" size="large" variant="contained" loading={isLoading}>
                  Save Changes
                </LoadingButton>
              </Stack>
            )}
          </Card>
        </Grid>
      </Grid>
      {hasQuiz && (
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={8}>
            <Stack spacing={3}>
                {!!tempQuiz?.rowOrder?.length && (
                  tempQuiz?.rowOrder.map((rowId, index) => (
                    <>
                      <Card sx={{ p: 3 }}>
                        <Stack>
                          <AddedQuiz index={index} key={rowId} row={tempQuiz.quiz[rowId]} />
                        </Stack>
                      </Card>
                    </>
                  ))
                )}

                <Stack>
                  {open && <AddQuiz onAddQuiz={handleAddQuiz} onCloseAddQuiz={handleCloseAddQuiz} />}
                  {!open && ( 
                    <Button
                      fullWidth
                      size="large"
                      color="inherit"
                      startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
                      onClick={handleOpenAddQuiz}
                      sx={{ fontSize: 14 }}
                    >
                      Add Quiz
                    </Button>
                  )}
                </Stack>

                <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
                  <Button color="secondary" size="small" onClick={onClearAllQuizes}>
                    Clear quizes
                  </Button>
                  <LoadingButton type="submit" size="medium" variant="contained" loading={isLoading}>
                    Save Changes
                  </LoadingButton>
                </Stack>
            </Stack>
          </Grid>
        </Grid>
      )}
    </FormProvider>
  );
}
