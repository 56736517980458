import React, { Suspense } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import LoadingScreen from '../../../components/LoadingScreen';
import { decryptUrlId } from '../../../utils/helpers';

const UpdateSectionComponent = React.lazy(() => import('./components/UpdateSection'));

const UpdateSection = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const store = useSelector((state) => ({
    sections: state.course.sections
  }), shallowEqual);

  const decryptedUrlId = decryptUrlId(id);
  const section = store.sections.find((section) => decryptedUrlId === section.id);

  return (
    <>
      {section && (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
          <UpdateSectionComponent section={section} />
        </Suspense>
      )}
    </>
  );
}

export default UpdateSection;