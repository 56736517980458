import { 
  SET_COURSES, SET_CATEGORIES, SET_SECTIONS,
  SET_LESSONS, CLEAR_COURSES, CLEAR_CATEGORIES,
  CLEAR_SECTIONS, CLEAR_LESSONS
} from '../../constants/constants';

const initState = {
  courses: [],
  categories: [],
  sections: [],
  lessons: []
};

export default (state = initState, action) => { // eslint-disable-line
  switch (action.type) {
    case SET_COURSES:
      return  {
        ...state,
        courses: action.payload.courses
      }
    case SET_CATEGORIES:
      return  {
        ...state,
        categories: action.payload.categories
      }
    case SET_SECTIONS:
      return  {
        ...state,
        sections: action.payload.sections
      }
    case SET_LESSONS:
      return  {
        ...state,
        lessons: action.payload.lessons
      }
    case CLEAR_COURSES:
      return state;
    case CLEAR_CATEGORIES:
      return {
        ...state,
        categories: []
      };
    case CLEAR_SECTIONS:
      return {
        ...state,
        sections: []
      };
    case CLEAR_LESSONS:
      return {
        ...state,
        lessons: []
      };
    default:
      return state;
  }
};
