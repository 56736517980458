import { 
  SET_WALLET, CLEAR_WALLET, 
} from '../../constants/constants';

export default (state = [], action) => { // eslint-disable-line
  switch (action.type) {
    case SET_WALLET:
      return  action.payload
    case CLEAR_WALLET:
      return [];
    default:
      return state;
  }
};
