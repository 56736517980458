import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as ROUTE from '../constants/routes'
import useDidMount from './useDidMount';

const useTransactions = ({pageNumber = 1}) => {
  const [transactionsList, setTransactionsList] = useState([]);
  const [transactionStat, setTransactionStat] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const isDidMount = useDidMount();

  const fetchTransactionsList = useCallback(async (_pageNumber) => {
    try {
      const authToken = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
      const requestOptions = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken.token}`
        }
      }
     
        setLoading(true);
        setError('');
        const _tnx =  await fetch(`${ROUTE.ADMIN_API}/transactions?page=${_pageNumber}`, 
          requestOptions)
        .then(res => res.json())
        .then(data =>{
          return data
        })
        .catch(err => console.log(err))
        if (!_tnx?.data) {
          if (isDidMount) {
            setError('No parent found.');
            setLoading(false);
          }
        } else {
          const data = _tnx;
          if (isDidMount) {
            setLoading(false);
            setTransactionsList(data);
          }
        }
      
    } catch (e) {
      if (isDidMount) {
        setError('Failed to fetch transactions');
        setLoading(false);
      }
    }
  }, [isDidMount]);

  const fetchTransactionStat = useCallback(async () => {
    try {
      setLoading(true);
      const authToken = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
      const requestOptions = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken.token}`
        }
      }
      if (transactionStat.length === 0 && isDidMount) {
        const _transactionStat =  await fetch(`${ROUTE.ADMIN_API}/transactions/stats`, 
          requestOptions)
        .then(res => res.json())
        .then(data =>{
          return data
        })
        .catch(err => console.log(err))
        if (!_transactionStat?.data) {
          if (isDidMount) {
            setLoading(false);
          }
        } else {
          const data = _transactionStat;
          if (isDidMount) {
            setLoading(false);
            setTransactionStat(data.data);
          }
        }
      }
    } catch (e) {
      if (isDidMount) {
        setError('Failed to fetch wallet balance');
        setLoading(false);
      }
    }
  }, [isDidMount, transactionStat]);

  useEffect(() => {
    fetchTransactionsList(pageNumber);
  }, [pageNumber]);

  return {
    fetchTransactionsList, transactionsList, fetchTransactionStat, transactionStat,
    isLoading, error
  };
};

export default useTransactions;

useTransactions.propTypes = {
  pageNumber: PropTypes.number,
};
