import React, { Suspense } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import LoadingScreen from '../../components/LoadingScreen';

const ViewWalletComponent = React.lazy(() => import('./components/ViewWallet'));

const ViewWallet = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const store = useSelector((state) => ({
    wallets: state.wallet
  }), shallowEqual);

  const wallet = store.wallets.find((wallet) => id === wallet.id);

  return (
    <>
      {wallet && (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
          <ViewWalletComponent 
            wallet={wallet}
          />
        </Suspense>
      )}
    </>
  );
}

export default ViewWallet;